













import Vue from 'vue';
import { HTTPError, isHTTPError } from '../../libs/http';
import {
  httpErrorToOAuth2Error,
  isResponseTypeValid,
  OAuth2Service,
} from '../../libs/oauth2';
import { routeToErrorPage } from '../../libs/util';

export default Vue.extend({
  data() {
    return {
      isLoading: false,

      request: OAuth2Service.getAuthorizationRequestFromRoute(this.$route),
    };
  },

  async mounted() {
    // Validate response type & client ID check
    if (
      isResponseTypeValid(this.request.response_type) === false ||
      !this.request.client_id
    ) {
      routeToErrorPage();
      return;
    }

    // If 'prompt=login' query is set, redirect to signin page
    if (
      typeof this.$route.query.prompt === 'string' &&
      this.$route.query.prompt?.toLowerCase() === 'login'
    ) {
      // Delete the prompt query, so the signin page doesn't use it
      delete this.$route.query.prompt;

      // Redirect to the signin page with same query
      this.$router.push({
        path: '/signin',
        query: this.$route.query,
      });

      return;
    }

    // Try authorization
    this.$set(this, 'isLoading', true);
    try {
      const authorization = await OAuth2Service.authorize(this.request);
      OAuth2Service.redirectToURI(authorization, this.request.redirect_uri);
      return;
    } catch (e) {
      // TODO: Redirect to server error
      // Pass the error too
      if (isHTTPError(e) === false) {
        routeToErrorPage();
        return;
      }

      const httpError: HTTPError = e;

      // If unauthorized, we've to authorize first by signing in
      // So redirect to signin page
      if (httpError.response?.status === 401) {
        this.$router.push({
          path: '/signin',
          query: this.$route.query,
        });

        return;
      }

      // If the HTTP error was a request error instead of response
      // Throw the 'temporarily unavailable' error to OAuth2 redirect URI
      if (!httpError.response) {
        OAuth2Service.redirectToURI(
          {
            error: 'temporarily_unavailable',
            error_description: 'Service unavailable',
          },
          this.request.redirect_uri,
        );
      }

      // If else than authorization error, it's probably a OAuth2 error
      // Which should be passed back to the redirect uri
      OAuth2Service.redirectToURI(
        httpErrorToOAuth2Error(httpError),
        this.request.redirect_uri,
      );
    }
  },
});
